import {
  EnvelopeIcon,
  EventIcon,
  ImageGalleryIcon,
  InstagramIcon,
  LinkIcon,
  MenuIcon,
  ShoppingCartIcon,
  YouTubeIcon
} from 'components/icons';

export default [
  // Simple
  {
    backgroundCSS: 'background: linear-gradient(45deg, #00CFCA 0%, #1EE6E4 50%)',
    description: ['Promote a single call to action.'],
    isEnabled: true,
    isAvailable: true,
    icon: LinkIcon,
    iconHeight: 28,
    iconWidth: 28,
    kind: 'simple',
    title: 'Simple',
  },

  // Links
  {
    backgroundCSS: 'background: linear-gradient(45deg, #019EF5 0%, #4ACDF9 75%)',
    description: ['Direct followers to multiple links from a single card.'],
    isEnabled: true,
    isAvailable: true,
    icon: MenuIcon,
    iconHeight: 28,
    iconWidth: 25,
    kind: 'links',
    title: 'Links',
  },

  // Instagram Links
  {
    backgroundCSS: `
      background: linear-gradient(30deg, #FEDA75, #FA7E1E 20%, #D62976 55%, #962FBF 80%, #4F5BD5);
      background-blend-mode: multiply;
      background-color: rgba(0, 0, 0, 0.1);
    `,
    description: ['Displays your Instagram posts with links.'],
    isEnabled: true,
    isAvailable: true,
    icon: InstagramIcon,
    iconHeight: 32,
    iconWidth: 32,
    kind: 'instagram',
    title: 'Instagram Links',
  },

  // Email Capture
  {
    backgroundCSS: 'background-color: blue;',
    description: ['Grow your list, or create a new one.'],
    isEnabled: true,
    isAvailable: true,
    icon: EnvelopeIcon,
    iconHeight: 28,
    iconWidth: 34,
    kind: 'mailing-list',
    title: 'Email Collection',
  },

  // YouTube
  {
    backgroundCSS: 'background-color: red;',
    description: ['Feature videos from your YouTube feed or channel.'],
    isBeta: false,
    isEnabled: true,
    isAvailable: true,
    icon: YouTubeIcon,
    iconHeight: 25,
    iconWidth: 35,
    kind: 'youtube',
    title: 'Youtube',
  },

  // Image gallery
  {
    backgroundCSS: 'background: linear-gradient(45deg, #00CFCA 0%, #A197CA 50%)',
    description: ['Feature images and GIFs with links.'],
    isBeta: true,
    isEnabled: true,
    isAvailable: true,
    icon: ImageGalleryIcon,
    iconHeight: 28,
    iconWidth: 28,
    kind: 'image-gallery',
    title: 'Image Gallery',
  },

  // Shop
  {
    backgroundCSS: 'background-color: green;',
    description: ['Feature products from your own store or promote your affiliates.'],
    isEnabled: false,
    isAvailable: false,
    icon: ShoppingCartIcon,
    iconHeight: 28,
    iconWidth: 34,
    kind: 'shop',
    title: 'Shop',
  },

  // Event
  {
    backgroundCSS: 'background-color: purple;',
    description: ['Promote your events with Eventbrite, Meetup and more.'],
    isEnabled: false,
    isAvailable: false,
    icon: EventIcon,
    iconHeight: 28,
    iconWidth: 34,
    kind: 'event',
    title: 'Event',
  },
];
